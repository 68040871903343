import React, { useState } from 'react';
import ModalForm from './ModalForm';

const LandingPage = () => {
  const [isHowToModalOpen, setHowToModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
        <h1>hello</h1>
      {/* Title Section */}
        <h1 className="text-white text-4xl font-bold mb-4">Scrape Apollo Leads</h1>
 
      <div className="mt-12 w-full max-w-4xl p-8 bg-green-400 rounded-lg shadow-lg text-white">
     
      <div className="grid grid-cols-3 gap-6">
        <div className="flex flex-col items-center">
          <p className="text-black text-4xl font-bold">600k </p>
          <p className="text-black text-xl">per month</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-black text-4xl font-bold">20k</p>
          <p className="text-black text-xl">per day</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-black text-4xl font-bold">4k</p>
          <p className="text-black text-xl">per hour</p>
        </div>
      </div>
    </div>
       

      {/* How To Section */}
      <button
        onClick={() => setHowToModalOpen(true)}
        className="text-black bg-green-400 hover:bg-green-neon mt-8 px-6 py-3 rounded-md shadow-md transition-colors duration-300"
      >
        How To Get Started
      </button>

      {/* Get Access Button */}
      <button
        onClick={() => window.open("https://www.youtube.com/", "_blank")}
        className="text-black bg-green-400 hover:bg-green-neon-light mt-8 px-6 py-3 rounded-md shadow-md transition-colors duration-300"
      >
        Get Access
      </button>

      {/* How To Modal */}
      {isHowToModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-4">How To Get Started</h2>
            <ol className="list-decimal pl-8">
            <li>Disclaimer! Your email must be approved to process request, Click "Get Access" if you are not approved.</li>
              <li>Fill out the form below with your Apollo search details</li>
              <li>Click the "Submit" button to process your request.</li>
              <li>Our list will be emailed to you via csv</li>
              
            </ol>
            <button
              onClick={() => setHowToModalOpen(false)}
              className="mt-6 bg-green-400 hover:bg-green-500 text-white px-6 py-3 rounded-md shadow-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
      

      
      {/* Form Section */}
      <div className="w-full max-w-md mt-8 bg-black p-8 rounded-lg shadow-lg">
        <ModalForm />
      </div>

      

      <h1>hello</h1>

      
    </div>

  );
};

export default LandingPage;
