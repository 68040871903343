import React, { useState } from 'react';
import axios from 'axios';
import './ModalForm.css'; // Import the CSS file for styling

const ModalForm = () => {
  const [formData, setFormData] = useState({
    url: '',
    numLeads: '',
    api_key: '',
    email: '',
    server_key: 'yolo',
    searchID: '',
  });

  const [responseText, setResponseText] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://eoiyoaf7fxnaf08.m.pipedream.net', formData);
      setResponseText(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error('Error:', error);
      setResponseText(JSON.stringify(error, null, 2));
    }
  };
  

  return (
    <div className="form-container">
      <h2>Input Search</h2>
      <form onSubmit={handleSubmit}>
        <label>
          URL:
          <input type="text" name="url" value={formData.url} onChange={handleChange} />
        </label>
        <label>
          Number of Leads:
          <input type="number" name="numLeads" value={formData.numLeads} onChange={handleChange} />
        </label>
        <label>
          API Key:
          <input type="text" name="api_key" value={formData.api_key} onChange={handleChange} />
        </label>
        <label>
          Email:
          <input type="text" name="email" value={formData.email} onChange={handleChange} />
        </label>
        
        <label>
          Search ID:
          <input type="text" name="searchID" value={formData.searchID} onChange={handleChange} />
        </label>
        <button
        type="submit"
        className="bg-green-400 hover:bg-blue text-black px-6 py-3 rounded-md shadow-md transition-colors duration-300 mt-4"
      >
        Submit
      </button>
      </form>
      {responseText && (
        <div className="response-container">
          <h3>Response:</h3>
          <pre>{responseText}</pre>
        </div>
      )}
      
    </div>
  );
};

export default ModalForm;
