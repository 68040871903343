import React from 'react';
import LandingPage from './LandingPage';

function App() {
  return (
    <div>
      <LandingPage/>
      
    </div>
  );
}

export default App;
